import React from "react";
import { Button, Menu } from "antd";
import { CloseOutlined } from "@ant-design/icons";

// components
import ButtonDropdown from "../button-dropdown";

// configs
import { GRANULARITIES } from "@src/config/query-builder";

// types
import { IGranularity, IMemberDimensions, IMemberTime } from "@src/types/query-builder";

type IProps = {
  members: IMemberDimensions[];
  timeDimensions: IMemberTime[];
  updateTimeMethods: any;
  onRemoveMembers: (member: IMemberDimensions) => void;
};

function MemberTagDimension({ members, timeDimensions, updateTimeMethods, onRemoveMembers }: IProps) {
  const granularityMenu = (onClick: (granularity: IGranularity) => void) => (
    <Menu>
      {GRANULARITIES.map((m) => (
        <Menu.Item key={m.title} onClick={() => onClick(m)}>
          {m.title}
        </Menu.Item>
      ))}
    </Menu>
  );

  function onUpdateGranularity(member: IMemberDimensions, granularity: IGranularity) {
    const dimensionItem = timeDimensions.find((m) => m.dimension.name === member.name);
    const payload: any = {
      dimension: {
        ...member,
      },
      granularity: granularity.name,
      index: dimensionItem ? dimensionItem.index : timeDimensions.length + 1,
    };
    if (dimensionItem) {
      payload.dateRange = dimensionItem.dateRange;
    }
    updateTimeMethods.update(payload, { ...payload, granularity: granularity.name });
  }

  return (
    <div className="flex flex-wrap mr-2">
      {members.map((member) => {
        const titleGranularity = timeDimensions.find((m) => m.dimension.name === member.name)?.granularity;
        return (
          <div key={member.title} className="mb-2 mr-2">
            <div className="rounded-md border-solid border-[1px] inline-block pl-1 py-1 border-[#1677ff]">
              {member.title}

              {member.type === "time" && (
                <>
                  <span className="text-[rgba(161,161,181,1)] inline-block mr-2 ml-2">by</span>
                  <ButtonDropdown
                    size="small"
                    overlay={granularityMenu((granularity: IGranularity) => {
                      onUpdateGranularity(member, granularity);
                    })}
                    className="capitalize"
                  >
                    {titleGranularity || GRANULARITIES[0].title}
                  </ButtonDropdown>
                </>
              )}
              <Button
                onClick={() => onRemoveMembers(member)}
                danger
                size="small"
                icon={<CloseOutlined className="mt-1 hover:text-[#f00]" style={{ color: "#1677ff" }} />}
                className="border-0 p-0 ml-2"
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default MemberTagDimension;
