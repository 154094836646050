import React, { PropsWithChildren } from "react";
import { CubeProvider } from "@cubejs-client/react";
import cubejs from "@cubejs-client/core";
import { useCustom } from "@refinedev/core";

// antd
import { FloatButton, Layout, Spin, theme } from "antd";
import { HeartOutlined } from "@ant-design/icons";

// configs
import { config } from "@src/config";

// components
import HeaderBar from "./components/header-bar";
import SideBar from "./components/side-bar";

// context
import { useColorContext } from "@src/contexts/color-context";
import { useAppContext } from "@src/contexts/app-context";

const { Header, Content, Footer } = Layout;

const UkuleleLayout: React.FC<PropsWithChildren> = ({ children }) => {
  // states
  const [collapsed, setCollapsed] = React.useState(true);
  // hooks
  const { mode } = useColorContext();
  const { product } = useAppContext();
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const { data, isLoading } = useCustom({
    url: `${config.BACKEND_URL}/product/${product?.productCode}/generate-cube-token`,
    method: "get",
    queryOptions: {
      enabled: Boolean(product?.productCode),
      retry: 0,
    },
    errorNotification: () => {
      return {
        type: "error",
        message: "There was an error fetching data",
        description: "Error",
      };
    },
  });

  if (isLoading) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <Spin />
      </div>
    );
  }
  const CUBE_TOKEN = data?.data?.jwt_token;

  return (
    <CubeProvider cubeApi={cubejs(CUBE_TOKEN, { apiUrl: process.env.VITE_CUBE_URL || "" })}>
      <Layout style={{ minHeight: "100vh" }}>
        <SideBar collapsed={collapsed} setCollapsed={setCollapsed} />
        <Layout style={{ marginLeft: collapsed ? 80 : 240 }}>
          <Header
            className="p-0 flex items-center justify-between px-5"
            style={{
              background: mode === "light" ? `#0050B3` : colorBgContainer,
            }}
          >
            <HeaderBar />
          </Header>
          <Content
            style={{
              minHeight: 280,
              background: "#eeeef5",
            }}
            className="bg-[#eeeef5] dark:bg-black"
          >
            {children}
          </Content>
          <div>
            <Footer
              className="border-[#E0E0E0] dark:border-[#2b2b2b] border-0 border-t-[1px] border-solid "
              style={{
                textAlign: "center",
                padding: 5,
                fontSize: 12,
                background: mode === "light" ? `#fff` : colorBgContainer,
              }}
            >
              ©2024, SAT {config.VERSION} with <HeartOutlined style={{ color: "#f00" }} /> by Amanotes
            </Footer>
          </div>
        </Layout>

        <FloatButton.BackTop />
      </Layout>
      <div>
        <FloatButton.BackTop />
      </div>
    </CubeProvider>
  );
};

export default UkuleleLayout;
