import React from "react";
// types
import { IMemberBase, IMemberMeasures, IMembersTreeBase } from "@src/types/query-builder";

// helpers
import { mapMemberToTreeNode } from "@src/helpers/tree-select-member";

// components
import MemberGroupBase from "./member-group-base";
import MemberTagBase from "./member-tag-base";

type IProps = IMembersTreeBase & {
  members: IMemberMeasures[];
};

function MemberGroupMeasures({
  title,
  description = "Select item to display",
  availableMembers,
  members,
  updateMethods,
  updateQuery,
  query,
  isFetchingMeta,
}: IProps) {
  const queryOrder = React.useMemo(() => query?.order || [], [query.order]);
  const cubOptions = React.useMemo(() => mapMemberToTreeNode(availableMembers), [availableMembers]);
  const getDefaultCheckedKeys = React.useMemo(
    () => (query?.measures ? query.measures.map((m: string) => m) : []),
    [query.measures],
  );

  function addMembers(members: React.Key[], membersUnSelected: React.Key[]) {
    const newOrders = queryOrder.filter((order: any) => !membersUnSelected.includes(order[0])); // update order when unselected member
    updateQuery({
      measures: members,
      order: newOrders,
    });
  }

  function onRemoveMember(member: IMemberBase) {
    // update query order
    const indexTimeInOrder = queryOrder.findIndex((order: any) => order[0] === member.name);
    if (indexTimeInOrder > -1) {
      queryOrder.splice(indexTimeInOrder, 1);
      setTimeout(() => {
        updateQuery({
          order: queryOrder,
        });
      });
    }

    updateMethods.remove(member);
  }

  return (
    <MemberGroupBase
      title={title}
      dataSource={cubOptions}
      query={query}
      isFetchingMeta={isFetchingMeta}
      getDefaultCheckedKeys={getDefaultCheckedKeys}
      onOk={(selectedMembers: React.Key[], membersUnSelected) => {
        addMembers(selectedMembers, membersUnSelected);
      }}
    >
      <div className="flex flex-wrap">
        {members.length > 0 ? (
          <div className="flex flex-wrap mr-2">
            {members.map((member) => {
              return (
                <div key={member.title} className="mb-2 mr-2">
                  <MemberTagBase title={member.title} member={member} onRemoveMember={onRemoveMember} />
                </div>
              );
            })}
          </div>
        ) : (
          <>{description}</>
        )}
      </div>
    </MemberGroupBase>
  );
}

export default MemberGroupMeasures;
