import { AuthBindings } from "@refinedev/core";

// configs
import { NAME_STORAGE } from "@src/config/storage";

// helpers
import { parseJwt } from "@src/helpers/parse-jwt";
import { clearStorage } from "@src/helpers/clear-storage";

// types
import { CredentialResponse } from "@src/types/google";
import { PAGE_URL } from "@src/config";

export const authProvider: AuthBindings = {
  login: async ({ credential }: CredentialResponse) => {
    const profileObj = credential ? parseJwt(credential) : null;
    if (profileObj) {
      localStorage.setItem(
        NAME_STORAGE.USER,
        JSON.stringify({
          ...profileObj,
          avatar: profileObj.picture,
        }),
      );
      localStorage.setItem(NAME_STORAGE.ACCESS_TOKEN, `${credential}`);
      window.location.href = PAGE_URL.ROOT;
      return {
        success: true,
        // redirectTo: "/",
      };
    }

    return {
      success: false,
    };
  },
  logout: async () => {
    const token = localStorage.getItem(NAME_STORAGE.ACCESS_TOKEN);

    // if (token && typeof window !== "undefined") {
    //   clearStorage(token);
    // }

    clearStorage(token || "");
    window.location.href = PAGE_URL.LOGIN;

    return {
      success: true,
      // redirectTo: "/login",
    };
  },
  onError: async (error) => {
    return { error };
  },
  check: async () => {
    const token = localStorage.getItem(NAME_STORAGE.ACCESS_TOKEN);

    if (token) {
      return {
        authenticated: true,
      };
    }

    return {
      authenticated: false,
      error: {
        message: "Check failed",
        name: "Token not found",
      },
      logout: true,
      redirectTo: "/login",
    };
  },
  getPermissions: async () => null,
  getIdentity: async () => {
    const user = localStorage.getItem(NAME_STORAGE.USER);
    if (user) {
      return JSON.parse(user);
    }

    return null;
  },
};
