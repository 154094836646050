import React from "react";
import { DeploymentUnitOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";

// types
import { IChart } from "@src/types/chart";

import { AxesPivot } from "./pivot/axes-pivot";

type IProps = {
  layout: IChart;
  setIsDisableDragGrid: React.Dispatch<React.SetStateAction<boolean>>;
};

function PivotGroup({ layout, setIsDisableDragGrid }: IProps) {
  const pivotRef = React.useRef<any>(null);
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const showModal = () => {
    setIsModalOpen(true);
    setIsDisableDragGrid(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setIsDisableDragGrid(false);
    pivotRef.current.updatePivot();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsDisableDragGrid(false);
    pivotRef.current.resetPivot();
  };

  return (
    <>
      <Button icon={<DeploymentUnitOutlined />} size="small" onMouseDown={showModal}>
        Pivot
      </Button>
      <Modal title="Pivot" className="modal-amanotes" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <div className="pivot-popover">
          <div data-testid="pivot-popover" className=" w-full">
            <AxesPivot ref={pivotRef} layout={layout} />
          </div>
        </div>
      </Modal>
    </>
  );
}

export default PivotGroup;
