import React from "react";
import { DatePicker } from "antd";
import { config } from "@src/config";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;
type IProps = {
  value?: string[];
  onChange: (_: any, dateStrings: [string, string]) => void;
};

function TimeDateRanger({ value = [], onChange }: IProps) {
  return (
    <>
      <RangePicker
        defaultValue={[
          value?.length > 0 ? dayjs(value[0], config.DATE_FORMAT) : null,
          value?.length > 0 ? dayjs(value[1], config.DATE_FORMAT) : null,
        ]}
        // style={{ width: "200px" }}
        onChange={onChange}
      />
    </>
  );
}

export default TimeDateRanger;
