import packageJSON from "../../package.json";

export const config = {
  BACKEND_URL: process.env.VITE_BACKEND_URL || "",
  SIDEBAR_WIDTH: 240,
  RECHART_HEIGHT: 500,
  DATE_FORMAT: "YYYY-MM-DD",
  VERSION: packageJSON.version,
};

// url
export { PAGE_URL } from "./page-url";
