import React from "react";
import RGL, { Layout, WidthProvider } from "react-grid-layout";
import { useCubeQuery } from "@cubejs-client/react";
import { Empty } from "antd";

// contexts
import { useAppContext } from "@src/contexts/app-context";

// types
import { IKeyChart } from "@src/types/chart";

// components
import ChartRenderer from "./chart-renderer";
import HeadingChart from "@src/components/chart/heading-chart";

// mocks
// import { queryBuilderMock } from "@src/mocks/query-builder-mock";

const ReactGridLayout = WidthProvider(RGL);

function LayoutItem({ layout }: any) {
  const { query } = layout.vizState;
  const renderProps = useCubeQuery(query);

  function getResultSet() {
    return renderProps.resultSet;
  }

  return (
    <React.Fragment key={layout.i}>
      <HeadingChart layout={layout} getResultSet={getResultSet} />
      <ChartRenderer vizState={layout.vizState} renderProps={renderProps} />
    </React.Fragment>
  );
}

function GridLayout() {
  const { charts, product, isDisableDragGrid } = useAppContext();
  const [layouts, setLayouts] = React.useState<Layout[]>([]);
  const [defaultProps] = React.useState({
    className: "layout",
    rowHeight: 115,
    cols: 12,
    autoSize: true,
    isResizable: true,
    isDraggable: true,
  });

  React.useEffect(() => {
    if (!product || !charts) return;
    const chartsOfProduct = charts?.[product?.productCode] || null;
    generateLayout(chartsOfProduct);
  }, [charts, product]);

  function generateLayout(payload: IKeyChart | null) {
    if (!payload) return;

    const newLayouts = Object.values(payload || {}).map((item, i) => {
      const w = 12;
      const h = 5;
      // tony
      // const w = 2 * 3;
      // const h = 3;
      const grid = {
        x: Number((i % 2) * w),
        y: Number(Math.floor(i / 3) * h),
        w,
        h,
        i: i.toString(),
        minH: h,
        maxH: h,
        ...item,
      };
      if (item.vizState.chartType === "table") {
        // grid.h = 4;
        // grid.minH = 4;
        // grid.maxH = 4;
      }
      return grid;
    });
    setLayouts(newLayouts);
  }

  return (
    <>
      {layouts.length > 0 ? (
        <ReactGridLayout
          {...defaultProps}
          draggableHandle={isDisableDragGrid ? ".draggable-handle" : ""}
          layout={layouts}
        >
          {layouts.map((layout) => (
            <div key={layout.i} data-grid={layout} className="dark:bg-[#141414]">
              <LayoutItem layout={layout} />
            </div>
          ))}
        </ReactGridLayout>
      ) : (
        <div className="flex items-center justify-center w-full h-full">
          <Empty />
        </div>
      )}
    </>
  );
}

export default GridLayout;
