import React from "react";
import { useLocation } from "react-router-dom";

// antd
import { AutoComplete, Input, SelectProps } from "antd";
import { DefaultOptionType } from "antd/es/select";

// hooks
import { useAppContext } from "@src/contexts/app-context";
import { useLocalStorage } from "@src/hooks/use-localstorage";

// configs
import { NAME_STORAGE } from "@src/config/storage";
import { sortBy } from "@src/helpers/sort-by";

type IProps = {
  setShowProductAutoComplete: (value: boolean) => void;
  showProductAutoComplete: boolean;
};

export const ProductAutoComplete = ({ setShowProductAutoComplete, showProductAutoComplete }: IProps) => {
  const location = useLocation();
  // states
  const [options, setOptions] = React.useState<SelectProps<object>["options"]>([]);
  const [value, setValue] = React.useState("");
  const [openAutoComplete, setOpenAutoComplete] = React.useState<boolean>(false);
  // refs
  const optionsRef = React.useRef<SelectProps<object>["options"]>([]);
  const searchBoxRef = React.useRef<any>(null);
  // hooks
  const [, saveProductStorage] = useLocalStorage(NAME_STORAGE.PRODUCT);
  const { setProduct, products, product } = useAppContext();

  // auto focus & open product autocomplete
  React.useEffect(() => {
    if (showProductAutoComplete) {
      searchBoxRef.current.focus();
      setOpenAutoComplete(true);
    }
  }, [showProductAutoComplete]);

  const handleSearch = (value: string) => {
    const optionsFiltered = [...(optionsRef.current || [])];
    const searchResult = optionsFiltered.filter((option) => {
      if (option.name) {
        const name = `${option.name} - ${option?.productCode || ""}`;
        return name.toLowerCase().includes(value.toLowerCase());
      }
      return false;
    });
    setOptions(value ? searchResult : optionsFiltered);
  };

  const handleSelect = (_: string, option: DefaultOptionType) => {
    if (product?.productCode === option.productCode) return;

    // close product autocomplete
    setOpenAutoComplete(false);
    setShowProductAutoComplete(false);

    const productItem = products.find((product) => product.productCode === option.productCode) || null;

    if (!productItem) return;

    setValue("");
    setProduct(option);
    saveProductStorage(productItem);

    // save localstorage
    // reload page
    window.location.href = `${location.pathname}?product=${productItem.productCode}`;
  };

  const onChange = (value: string) => {
    setValue(value);
  };

  React.useEffect(() => {
    if (!products) return;
    const options = sortBy(products, "name").map((product) => {
      return {
        value: product.productCode,
        title: product.name,
        ...product,
        label: (
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div className="w-[44px] h-[44px] relative mr-4">
                <img
                  className="rounded-lg w-[40px] h-[40px] object-cover"
                  src={product.icon}
                  alt="Product application"
                />
              </div>
              <div>
                {product.name} - {product.productCode}
              </div>
            </div>
          </div>
        ),
      };
    });
    optionsRef.current = options;
    setOptions(options);
  }, [products]);

  return (
    <AutoComplete
      allowClear
      popupMatchSelectWidth={500}
      style={{ width: 250 }}
      options={options}
      onSearch={handleSearch}
      onSelect={handleSelect}
      value={value}
      onChange={onChange}
      open={openAutoComplete}
      onBlur={() => setOpenAutoComplete(false)}
    >
      <Input size="large" placeholder="Search products" ref={searchBoxRef} />
    </AutoComplete>
  );
};
