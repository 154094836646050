import { Card } from "antd";

// components
import LimitDropdown from "./limit-dropdowm";
import { IQuery } from "@src/types/query-builder";

type IProps = {
  title: string;
  query: IQuery;
  isQueryPresent: boolean;
  updateQuery: any;
};

export default function LimitGroup({ title, query, isQueryPresent, updateQuery }: IProps) {
  return (
    <Card size="small" title={title} className="h-full">
      <LimitDropdown query={query} limit={query.limit || 100} disabled={!isQueryPresent} updateQuery={updateQuery} />
    </Card>
  );
}
