import React from "react";
import { Authenticated, Refine } from "@refinedev/core";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import routerBindings, { CatchAllNavigate, NavigateToResource } from "@refinedev/react-router-v6";
import { ErrorComponent, useNotificationProvider } from "@refinedev/antd";
import dataProvider from "@refinedev/simple-rest";
import { ReactFlowProvider } from "reactflow";

// antd icons
import {
  CodeSandboxOutlined,
  FundOutlined,
  ThunderboltOutlined,
  // HomeOutlined,
  // LoadingOutlined,
  // SettingFilled,
  // SmileOutlined,
  // FunnelPlotOutlined,
} from "@ant-design/icons";

// components
import { UkuleleLayout } from "@src/layouts/ukulele-layout";

// configs
import { PAGE_URL, config } from "./config";

// contexts
import { AppContextProvider } from "@src/contexts/app-context";
import { ColorContextProvider } from "@src/contexts/color-context";
import { MultiStepQueryContextProvider } from "./contexts/multi-step-query-context";

// libs
import { axiosInstance, initRequest } from "./libs/axios-instance";
import { authProvider } from "./libs/auth-provider";

// pages
import { Dashboard } from "@src/pages/dashboard";
import { FunnelAnalysis } from "@src/pages/funnel-analysis";
import { PathAnalysis } from "@src/pages/path-analysis";
import { DrillerAnalysis } from "@src/pages/driller-analysis";
import { MultiStepQuery } from "@src/pages/multi-step-query";
import { SavedExploration } from "@src/pages/workpsace/saved-exploration";
import { Error401 } from "@src/pages/error/401";
import { Error403 } from "@src/pages/error/403";
import { Login } from "@src/pages/login";
import { Funnel } from "@src/pages/advanced-analytics/funnel";

initRequest();

function App() {
  return (
    <BrowserRouter>
      <Refine
        dataProvider={dataProvider(config.BACKEND_URL, axiosInstance)}
        notificationProvider={useNotificationProvider}
        routerProvider={routerBindings}
        authProvider={authProvider}
        resources={[
          {
            name: "Exploration",
            meta: {
              icon: <FundOutlined />,
            },
          },
          {
            name: "Firebase explorer",
            list: PAGE_URL.ROOT,
            meta: {
              parent: "Exploration",
            },
          },
          {
            name: "User funnels",
            list: PAGE_URL.FUNNEL_ANALYSIS,
            meta: {
              parent: "Exploration",
              tags: ["C2"],
            },
          },
          {
            name: "User journeys",
            list: PAGE_URL.PATH_ANALYSIS,
            meta: {
              parent: "Exploration",
              tags: ["C2"],
            },
          },
          {
            name: "Driller",
            list: PAGE_URL.DATA_DRILLER,
            meta: {
              parent: "Exploration",
              tags: ["C2"],
            },
          },
          {
            name: "Multi step query",
            list: PAGE_URL.MULTI_STEP_QUERY,
            meta: {
              parent: "Exploration",
              tags: ["C2"],
            },
          },
          {
            name: "Advanced analytics",
            meta: {
              icon: <ThunderboltOutlined />,
            },
          },
          {
            name: "Funnel",
            list: PAGE_URL.ADVANCED_ANALYTICS.FUNNEL,
            meta: {
              parent: "Advanced analytics",
              tags: ["DEV"],
            },
          },
          {
            name: "Your workspace",
            meta: {
              icon: <CodeSandboxOutlined />,
            },
          },
          {
            name: "Saved exploration",
            list: PAGE_URL.WORKSPACE.SAVED_EXPLORATION,
            meta: {
              parent: "Your workspace",
              tags: ["C2"],
            },
          },
        ]}
        options={{
          syncWithLocation: true,
          warnWhenUnsavedChanges: true,
          useNewQueryKeys: true,
        }}
      >
        <ColorContextProvider>
          <AppContextProvider>
            <Routes>
              <Route
                path={PAGE_URL.ROOT}
                element={
                  <Authenticated key="authenticated-inner" fallback={<CatchAllNavigate to={PAGE_URL.LOGIN} />}>
                    <UkuleleLayout>
                      <Outlet />
                    </UkuleleLayout>
                  </Authenticated>
                }
              >
                <Route path={PAGE_URL.ROOT} element={<Dashboard />} />
                <Route path={PAGE_URL.FUNNEL_ANALYSIS} element={<FunnelAnalysis />} />
                <Route path={PAGE_URL.PATH_ANALYSIS} element={<PathAnalysis />} />
                <Route path={PAGE_URL.DATA_DRILLER} element={<DrillerAnalysis />} />
                <Route
                  path={PAGE_URL.MULTI_STEP_QUERY}
                  element={
                    <ReactFlowProvider>
                      <MultiStepQueryContextProvider>
                        <MultiStepQuery />
                      </MultiStepQueryContextProvider>
                    </ReactFlowProvider>
                  }
                />
                <Route path={PAGE_URL.WORKSPACE.SAVED_EXPLORATION} element={<SavedExploration />} />
                <Route path={PAGE_URL.ADVANCED_ANALYTICS.FUNNEL} element={<Funnel />} />
                <Route path="*" element={<ErrorComponent />} />
              </Route>
              <Route
                element={
                  <Authenticated key="authenticated-outer" fallback={<Outlet />}>
                    <NavigateToResource />
                  </Authenticated>
                }
              >
                <Route path={PAGE_URL.LOGIN} element={<Login />} />
              </Route>

              {/* Error Route */}
              <Route
                path={PAGE_URL.ERROR}
                element={
                  <Authenticated key="authenticated-inner" fallback={<CatchAllNavigate to={PAGE_URL.LOGIN} />}>
                    <Outlet />
                  </Authenticated>
                }
              >
                <Route path={PAGE_URL.ERROR_401} element={<Error401 />} />
                <Route path={PAGE_URL.ERROR_403} element={<Error403 />} />
              </Route>

              <Route element={<Outlet />}>
                <Route path="*" element={<ErrorComponent />} />
              </Route>
            </Routes>
          </AppContextProvider>
        </ColorContextProvider>
      </Refine>
    </BrowserRouter>
  );
}

export default App;
