import { HttpError } from "@refinedev/core";
import { PAGE_URL } from "@src/config";
import { NAME_STORAGE } from "@src/config/storage";
// import { clearStorage } from "@src/helpers/clear-storage";
import axios from "axios";

export const axiosInstance = axios.create();

export function initRequest() {
  const token = localStorage.getItem(NAME_STORAGE.ACCESS_TOKEN);

  axiosInstance.interceptors.request.use((config) => {
    if (config.headers && token) {
      config.headers["x-sat-user-token"] = `Bearer ${token}`;
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  });
  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      console.log("axios interceptor: ", error);
      const createCustomError = (message: string, statusCode: number) => {
        return {
          ...error,
          message,
          statusCode,
        };
      };
      const statusCode = error.response?.status;
      const message =
        error.response?.data?.error?.message ||
        error.response?.data.message ||
        `You don’t have permission to perform this action`;

      const customError: HttpError = createCustomError(message, statusCode);
      // token expired
      if (statusCode === 401 && token) {
        // clearStorage(token);
        window.location.href = PAGE_URL.ERROR_401;
      }

      // 403 forbidden
      if (statusCode === 403) {
        window.location.href = PAGE_URL.ERROR_403;
      }

      return Promise.reject(customError);
    },
  );
}
