import React from "react";
import { Button, Modal, Form, Input, Alert, CollapseProps, Collapse, Popconfirm, Tabs, Row, Col } from "antd";
import { ConsoleSqlOutlined, DeploymentUnitOutlined, ExperimentOutlined } from "@ant-design/icons";

// context
import { useAppContext } from "@src/contexts/app-context";
import { useExploreContext } from "@src/contexts/explore-context";

// configs
import { DEFAULT_QUERY_BUILDER } from "@src/config/query-builder";

// types
import { IUpdateMethods } from "@src/types/query-builder";

// helper
import { playgroundActionUpdateMethods } from "@src/helpers/update-method-query";

// components
import OrderGroup from "@src/components/query-builder/order-group";
import QueryViewer from "@src/components/query-builder/query-viewer";
import LimitGroup from "@src/components/query-builder/limit-group";
import JsonViewer from "@src/components/query-builder/json-viewer";

// dropdown v1
// import MemberGroupDimension from "@src/components/query-builder/member-group-dimension";
// import MemberGroupCard from "@src/components/query-builder/member-group-card";
// import TimeGroup from "@src/components/query-builder/time-group";
// import FilterGroup from "@src/components/query-builder/filter-group";

// dropdown v2
import MemberGroupDimensions from "@src/components/query-builder/v2/member-group-dimensions";
import MemberGroupMeasures from "@src/components/query-builder/v2/member-group-measures";
import MemberGroupSegments from "@src/components/query-builder/v2/member-group-segment";
import MemberGroupTime from "@src/components/query-builder/v2/member-group-time";
import MemberGroupFilter from "@src/components/query-builder/v2/member-group-filter";

const suggestionOptions = [
  {
    name: "song_start by song_play_type",
    query: {
      limit: 99,
      measures: ["firebase.event_count"],
      timeDimensions: [
        {
          dimension: "firebase.event_timestamp",
          // granularity: "day",
          dateRange: "Last 7 days",
        },
      ],
      order: [["firebase.event_count", "desc"]],
      dimensions: ["firebase.ep__song_play_type"],
      filters: [
        {
          member: "firebase.event_name",
          operator: "equals",
          values: ["song_start"],
        },
      ],
    },
  },
  {
    name: "song_* events count",
    query: {
      limit: 100,
      measures: ["firebase.event_count"],
      timeDimensions: [
        {
          dimension: "firebase.event_timestamp",
          // granularity: "day",
          dateRange: "Last 7 days",
        },
      ],
      order: [["firebase.event_count", "desc"]],
      dimensions: ["firebase.event_name", "firebase.ama_app_id"],
      filters: [
        {
          member: "firebase.event_name",
          operator: "equals",
          values: ["song_start", "song_fail", "song_revive", "song_end", "song_result"],
        },
      ],
    },
  },
  {
    name: "me_* events count",
    query: {
      limit: 100,
      measures: ["firebase.event_count"],
      timeDimensions: [
        {
          dimension: "firebase.event_timestamp",
          // granularity: "day",
          dateRange: "Last 7 days",
        },
      ],
      order: [["firebase.event_count", "desc"]],
      dimensions: ["firebase.ama_app_id", "firebase.event_name"],
      filters: [
        {
          member: "firebase.event_name",
          operator: "equals",
          values: ["me_start", "me_result"],
        },
      ],
    },
  },
  {
    name: "ad_impression by ad_format",
    query: {
      measures: ["firebase.user_count", "firebase.event_count", "firebase.ep__value__sum"],
      timeDimensions: [
        {
          dimension: "firebase.event_timestamp",
          dateRange: "Last 7 days",
        },
      ],
      order: [["firebase.event_count", "desc"]],
      filters: [
        {
          member: "firebase.event_name",
          operator: "equals",
          values: ["ad_impression"],
        },
      ],
      dimensions: ["firebase.ama_app_id", "firebase.ep__ad_format"],
    },
  },
  {
    name: "Top 10 version by user count",
    query: {
      limit: 10,
      measures: ["firebase.user_count"],
      timeDimensions: [
        {
          dimension: "firebase.event_timestamp",
          dateRange: "Last 7 days",
        },
      ],
      order: [["firebase.event_count", "desc"]],
      dimensions: ["firebase.app_info__version"],
    },
  },
];

function ExploreQuery() {
  const [form] = Form.useForm();
  const {
    charts,
    product,
    addChart,
    editChart,
    isOpenExploreModal,
    setIsOpenExploreModal,
    setChartDefault,
    chartDefault,
    runOnceEditOrder,
    setIsStartEditOrder,
  } = useAppContext();

  const {
    query,
    updateQuery,
    isQueryPresent,
    measures,
    availableMeasures,
    updateMeasures,
    dimensions,
    availableDimensions,
    filters,
    updateFilters,
    timeDimensions,
    availableTimeDimensions,
    updateTimeDimensions,
    segments,
    availableSegments,
    updateSegments,
    error,
    pivotConfig,
    isFetchingMeta,
  } = useExploreContext();

  // initialize query builder
  React.useEffect(() => {
    if (!chartDefault) return;
    // updateQuery(chartDefault.vizState.query || DEFAULT_QUERY_BUILDER);
    form.setFieldsValue({ name: chartDefault.name });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartDefault]);

  function resetQueryBuilder() {
    updateQuery(DEFAULT_QUERY_BUILDER);
  }

  const initialValues = {
    name: "",
  };

  function onFinish(values: any) {
    const name = values.name || "Untitled";
    const payload: any = {
      ...query,
      limit: query.limit || 100,
    };

    if (chartDefault?.action === "edit") {
      const chartItem = {
        ...chartDefault,
        name,
        vizState: {
          query: payload,
          pivotConfig,
          chartType: chartDefault.vizState.chartType,
        },
      };
      editChart(chartItem);
    } else {
      const chartItem = {
        id: Date.now(),
        name,
        vizState: {
          query: payload,
          chartType: "table",
          pivotConfig,
        },
      };

      addChart(chartItem); // update query builder context
    }

    // reset
    resetExploreBuilder();

    // scroll to bottom
    setTimeout(() => {
      window.scroll({ top: document.body.scrollHeight, behavior: "smooth" });
    }, 500);
  }

  function resetExploreBuilder() {
    form.resetFields();
    setIsOpenExploreModal(false);
    setChartDefault(null);
    runOnceEditOrder.current = true;
    setIsStartEditOrder(false);
    setTimeout(() => {
      resetQueryBuilder();
    });
  }

  const suggestions: CollapseProps["items"] = [
    {
      key: "1",
      label: "Common explorations",
      children: (
        <div>
          {suggestionOptions.map((opt: any, index: any) => {
            return (
              <React.Fragment key={index}>
                <Button
                  className="mb-2 mr-2"
                  onClick={() => {
                    updateQuery(opt.query);
                    // _.isEqual(query, opt.query)
                  }}
                >
                  {opt.name}
                </Button>
              </React.Fragment>
            );
          })}
        </div>
      ),
    },
  ];

  return (
    <Form form={form} initialValues={initialValues} onFinish={onFinish}>
      <div className="text-right mt-2 mr-2.5">
        {Object.keys(charts?.[product?.productCode || ""] || {}).length === 5 ? (
          <Popconfirm
            title="Information"
            description={
              <>
                You reach the limit of <b>5</b> charts. Please delete <br />
                some charts to create new one.
              </>
            }
            showCancel={false}
            // onConfirm={confirm}
            // onCancel={cancel}
            okButtonProps={{ hidden: true }}
            trigger={"hover"}
          >
            <Button type="primary" size="middle" disabled>
              Explore
            </Button>
          </Popconfirm>
        ) : (
          <Button type="primary" size="middle" onClick={() => setIsOpenExploreModal(true)}>
            Explore
          </Button>
        )}
      </div>
      <Modal
        title="Explore Builder"
        // key={isOpenExploreModal.toString()}
        open={isOpenExploreModal}
        width={850}
        className="modal-amanotes"
        okButtonProps={{ disabled: !isQueryPresent || Boolean(error?.message), htmlType: "submit" }}
        okText="Submit"
        onOk={() => {
          form.submit();
        }}
        onCancel={() => {
          resetExploreBuilder();
        }}
      >
        {error?.message && <Alert className="mb-3" message="Error" description={error.message} type="error" showIcon />}
        <Tabs
          defaultActiveKey="1"
          items={[
            {
              key: "1",
              label: "Query builder",
              icon: <ExperimentOutlined />,
              children: (
                <div>
                  <div className="pb-4">
                    <div className="font-bold mb-2">Title</div>
                    <Form.Item name="name" noStyle>
                      <Input type="text" name="name" placeholder="Exploration short title" />
                    </Form.Item>
                  </div>

                  <Collapse size="small" style={{ width: "100%" }} items={suggestions} className="custom-collapse" />
                  <Row gutter={16} className="my-2">
                    <Col span={24}>
                      <MemberGroupDimensions
                        title="Dimensions"
                        dimensions={dimensions}
                        timeDimensions={timeDimensions}
                        availableMembers={availableDimensions}
                        updateTimeMethods={playgroundActionUpdateMethods(updateTimeDimensions)}
                        updateQuery={updateQuery}
                        query={query}
                        isFetchingMeta={isFetchingMeta}
                      />
                    </Col>
                  </Row>
                  <Row gutter={16} className="mb-2">
                    <Col span={24}>
                      <MemberGroupMeasures
                        title="Measures"
                        members={measures}
                        availableMembers={availableMeasures}
                        updateMethods={playgroundActionUpdateMethods(updateMeasures) as IUpdateMethods}
                        query={query}
                        updateQuery={updateQuery}
                        isFetchingMeta={isFetchingMeta}
                      />
                    </Col>
                  </Row>
                  <Row gutter={16} className="mb-2">
                    <Col span={24}>
                      <MemberGroupSegments
                        title="Segments"
                        members={segments}
                        availableMembers={availableSegments}
                        updateMethods={playgroundActionUpdateMethods(updateSegments)}
                        query={query}
                        updateQuery={updateQuery}
                        isFetchingMeta={isFetchingMeta}
                      />
                    </Col>
                  </Row>
                  <Row gutter={16} className="mb-2">
                    <Col span={24}>
                      <MemberGroupTime
                        title="Time"
                        members={timeDimensions}
                        availableMembers={availableTimeDimensions}
                        updateMethods={playgroundActionUpdateMethods(updateTimeDimensions)}
                        query={query}
                        updateQuery={updateQuery}
                        isFetchingMeta={isFetchingMeta}
                      />
                    </Col>
                  </Row>
                  <Row gutter={16} className="mb-2">
                    <Col span={24}>
                      <MemberGroupFilter
                        title="Filter"
                        members={filters}
                        availableMembers={availableDimensions.concat(availableMeasures as any)}
                        updateMethods={playgroundActionUpdateMethods(updateFilters)}
                        query={query}
                        updateQuery={updateQuery}
                        isFetchingMeta={isFetchingMeta}
                      />
                    </Col>
                  </Row>
                  {/* <Row gutter={16}>
                    <Col span={24}>
                      <MemberGroupDimension
                        title="Dimensions v1"
                        description="Select dimensions to display"
                        dimensions={dimensions}
                        timeDimensions={timeDimensions}
                        availableMembers={availableDimensions}
                        updateMethods={playgroundActionUpdateMethods(updateDimensions)}
                        updateTimeMethods={playgroundActionUpdateMethods(updateTimeDimensions)}
                        updateQuery={updateQuery}
                        query={query}
                      />
                    </Col>
                  </Row>
                  <br /> */}
                  {/* <Row gutter={16}>
                    <Col span={24}>
                      <MemberGroupCard
                        title="Measures v1"
                        description="Select measures to display"
                        members={measures}
                        availableMembers={availableMeasures}
                        updateMethods={playgroundActionUpdateMethods(updateMeasures)}
                        allowedMembers={undefined}
                        updateQuery={updateQuery}
                        queryOrder={query.order || []}
                      />
                    </Col>
                  </Row>
                  <br /> */}
                  {/* <Row gutter={16}>
                    <Col span={24}>
                      <MemberGroupCard
                        title="Segments v1"
                        description="Select segments to display"
                        members={segments}
                        availableMembers={availableSegments}
                        updateMethods={playgroundActionUpdateMethods(updateSegments)}
                        allowedMembers={undefined}
                        updateQuery={updateQuery}
                        queryOrder={query.order || []}
                      />
                    </Col>
                  </Row> */}
                  {/* <Row gutter={16}>
                    <Col span={24}>
                      <TimeGroup
                        title="Time"
                        description="Select time to display"
                        members={timeDimensions}
                        availableMembers={availableTimeDimensions}
                        updateMethods={playgroundActionUpdateMethods(updateTimeDimensions)}
                        allowedMembers={undefined}
                      />
                    </Col>
                  </Row> */}
                  {/* <br />
                  <Row gutter={16}>
                    <Col span={24}>
                      <FilterGroup
                        title="Filter v1"
                        description="Select filter to display"
                        members={filters}
                        availableMembers={availableDimensions.concat(availableMeasures as any)}
                        // availableMembers={availableFilterMembers}
                        updateMethods={playgroundActionUpdateMethods(updateFilters)}
                        allowedMembers={undefined}
                      />
                    </Col>
                  </Row>
                  <br /> */}
                  <Row gutter={16}>
                    <Col span={14}>
                      <OrderGroup
                        title="Order"
                        timeDimensions={timeDimensions}
                        dimensions={dimensions}
                        measures={measures}
                        query={query}
                        updateQuery={updateQuery}
                      />
                    </Col>
                    <Col span={10}>
                      <LimitGroup
                        title="Limit"
                        query={query}
                        isQueryPresent={isQueryPresent}
                        updateQuery={updateQuery}
                      />
                    </Col>
                  </Row>
                </div>
              ),
            },
            {
              key: "2",
              label: "SQL Query",
              icon: <ConsoleSqlOutlined />,
              children: <QueryViewer query={query} />,
            },
            {
              key: "3",
              label: "JSON Query",
              icon: <DeploymentUnitOutlined />,
              children: <JsonViewer query={query} />,
            },
          ]}
        />
      </Modal>
    </Form>
  );
}

export default ExploreQuery;
