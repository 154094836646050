import { NAME_STORAGE } from "@src/config/storage";
import { axiosInstance } from "@src/libs/axios-instance";

export const clearStorage = (token = "") => {
  window.localStorage.removeItem(NAME_STORAGE.ACCESS_TOKEN);
  window.localStorage.removeItem(NAME_STORAGE.USER);
  axiosInstance.defaults.headers.common = {};
  window.google?.accounts.id.revoke(token, () => {
    return {};
  });
};
