import React from "react";
import { Menu } from "antd";

// components
import ButtonDropdown from "./button-dropdown";
import TimeDateRanger from "./time-date-ranger";

// types
import { DATE_RANGES } from "@src/config/query-builder";
import { IMemberTime } from "@src/types/query-builder";

// hooks
import { useAppContext } from "@src/contexts/app-context";

type IProps = {
  member: IMemberTime;
  updateMethods: any;
};

function TimeGroupITem({ member, updateMethods }: IProps) {
  const { chartDefault } = useAppContext();
  const runOnceTimeRender = React.useRef(true);
  const [isRangePickerVisible, setIsRangePickerVisible] = React.useState(false);

  React.useEffect(() => {
    const isDateRange = member.dimension.type === "time" && Array.isArray(member.dateRange);
    const isEdit = chartDefault?.action === "edit";
    if (!isRangePickerVisible && isDateRange && runOnceTimeRender.current && isEdit) {
      runOnceTimeRender.current = false;
      toggleRangePicker(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [member, chartDefault]);

  // const granularityMenu = (member: any, onClick: any) => (
  //   <Menu>
  //     {member.granularities.length ? (
  //       member.granularities.map((m: any) => (
  //         <Menu.Item key={m.title} onClick={() => onClick(m)}>
  //           {m.title}
  //         </Menu.Item>
  //       ))
  //     ) : (
  //       <Menu.Item disabled>No members found</Menu.Item>
  //     )}
  //   </Menu>
  // );

  const dateRangeMenu = (onClick: any) => (
    <Menu>
      {DATE_RANGES.map((m) => (
        <Menu.Item key={m.title || m.value} onClick={() => onClick(m)}>
          {m.title || m.value}
        </Menu.Item>
      ))}
    </Menu>
  );

  function toggleRangePicker(isVisible: boolean) {
    setIsRangePickerVisible(isVisible);
  }

  function onDateRangeSelect(m: IMemberTime, dateRange: [string, string]) {
    if (dateRange && !dateRange.some((d) => !d)) {
      updateMethods.update(m, {
        ...m,
        dateRange,
      });
    }
  }

  return (
    <>
      <span key={`${member.dimension.name}-for`} className="text-[rgba(161,161,181,1)]">
        for
      </span>
      <ButtonDropdown
        overlay={dateRangeMenu((dateRange: any) => {
          if (dateRange.value === "custom") {
            toggleRangePicker(true);
          } else {
            updateMethods.update(member, { ...member, dateRange: dateRange.value });
            toggleRangePicker(false);
          }
        })}
        style={{ marginLeft: 8, marginRight: 8 }}
        key={`${member.dimension.name}-date-range`}
      >
        {/* {m.dateRange || "All time"} */}
        {isRangePickerVisible ? "Custom" : member.dateRange || "All time"}
      </ButtonDropdown>

      {isRangePickerVisible ? (
        <div className="inline mr-2">
          <TimeDateRanger
            value={Array.isArray(member.dateRange) ? member.dateRange : []}
            onChange={(_, dates) => {
              onDateRangeSelect(member, dates);
            }}
          />
        </div>
      ) : null}

      {/* uncomment if you want add granularity */}
      {/* <span key={`${member.dimension.name}-by`} className="text-[rgba(161,161,181,1)]">
        by
      </span>
      <ButtonDropdown
        overlay={granularityMenu(member.dimension, (granularity: any) => {
          console.log("time-group-item: ", {
            member,
          });

          updateMethods.update(member, { ...member, granularity: granularity.name });
        })}
        key={`${member.dimension.name}-granularity`}
        className="ml-2 mr-2"
      >
        {member.dimension.granularities.find((g: any) => g.name === member.granularity) &&
          member.dimension.granularities.find((g: any) => g.name === member.granularity).title}
      </ButtonDropdown> */}
    </>
  );
}

export default TimeGroupITem;
