import { useLogin } from "@refinedev/core";
import React from "react";

// assets
import bgLogin from "@src/assets/images/bg-login.jpeg";
import IconAmanotes from "@src/assets/images/amanotes_icon.png";

// types
import { CredentialResponse } from "@src/types/google";

function Login() {
  const { mutate: login } = useLogin<CredentialResponse>();

  const GoogleButton = (): JSX.Element => {
    const divRef = React.useRef<HTMLDivElement>(null);
    React.useEffect(() => {
      if (typeof window === "undefined" || !window.google || !divRef.current) {
        return;
      }

      try {
        window.google.accounts.id.initialize({
          ux_mode: "popup",
          client_id: process.env.VITE_GOOGLE_CLIENT_ID || "",
          callback: async (res: CredentialResponse) => {
            if (res.credential) {
              login(res);
            }
          },
        });
        window.google.accounts.id.renderButton(divRef.current, {
          theme: "filled_blue",
          size: "medium",
          type: "standard",
        });
      } catch (error) {
        console.log(error);
      }
    }, []);

    return <div className="flex justify-center mt-6" ref={divRef} />;
  };

  return (
    <div
      style={{
        background: `url(${bgLogin}) no-repeat center center fixed`,
        backgroundSize: "cover",
      }}
    >
      <div style={{ height: "100vh", display: "flex" }}>
        <div className="bg-black/[0.6] w-full px-4 py-4 rounded flex items-center flex-col justify-center">
          <div className="flex flex-col mb-4 my-auto p-4 rounded bg-white max-w-[400px] w-full">
            <div className="text-[20px] font-bold mb-4 text-center">SAT - Self Service Analytics Tools</div>
            <img src={IconAmanotes} alt="Amanotes" className="w-[100px] mx-auto" />
            <GoogleButton />
          </div>
          <div className="mt-auto text-white">© 2024 Amanotes. All Rights Reserved.</div>
        </div>
      </div>
    </div>
  );
}

export default Login;
