export const products = [
  {
    icon: "https://storage.googleapis.com/product-management-portal/production/Screenshot_2024_03_11_at_17_05_49_8e7099bc7b/Screenshot_2024_03_11_at_17_05_49_8e7099bc7b.png",
    name: "Beat Blader 3D",
    productCode: "BELLA",
  },
  {
    icon: "https://storage.googleapis.com/product-management-portal/production/Screenshot_2024_04_29_at_11_21_53_82a5338a38/Screenshot_2024_04_29_at_11_21_53_82a5338a38.png",
    name: "Cat EDM",
    productCode: "GINA",
  },
  {
    icon: "https://storage.googleapis.com/product-management-portal/production/unnamed_1ad4eb7da6/unnamed_1ad4eb7da6.png",
    name: "Cat Tiles",
    productCode: "CATY",
  },
  {
    icon: "https://storage.googleapis.com/product-management-portal/production/icon_01_0f4e9a6895/icon_01_0f4e9a6895.png",
    name: "Duet Cats",
    productCode: "GDUC",
  },
  {
    icon: "https://storage.googleapis.com/product-management-portal/production/icon_9a352efbb5/icon_9a352efbb5.png",
    name: "Duet Friends: Kawaii Music",
    productCode: "GDUF",
  },
  {
    icon: "https://storage.googleapis.com/product-management-portal/production/small_231004_GDUT_GL_I3b1_GP_Only0_Split_Light_BG_4_4f14612ab6/small_231004_GDUT_GL_I3b1_GP_Only0_Split_Light_BG_4_4f14612ab6.png",
    name: "Duet Tiles",
    productCode: "GDUT",
  },
  {
    icon: "https://storage.googleapis.com/product-management-portal/production/com_amanotes_pamadancingroad_9a88f4cec3/com_amanotes_pamadancingroad_9a88f4cec3.png",
    name: "Dancing Road",
    productCode: "DR",
  },
  {
    icon: "https://storage.googleapis.com/product-management-portal/production/com_youmusic_magictiles_4034d39b8d/com_youmusic_magictiles_4034d39b8d.jpeg",
    name: "Magic Tiles 3",
    productCode: "PI",
  },
  {
    icon: "https://storage.googleapis.com/product-management-portal/production/piano_magic_tiles_challenge_music_free_icon_2023_09_13_21_59_05_cda0e9f420/piano_magic_tiles_challenge_music_free_icon_2023_09_13_21_59_05_cda0e9f420.png",
    name: "Magic Tiles Kid",
    productCode: "FIONA",
  },
  {
    icon: "https://storage.googleapis.com/product-management-portal/production/240419_DORY_GL_INA_FE_0_Lay0_G_Ch0_Duet_Char_1024_X1024_d74b11db1a/240419_DORY_GL_INA_FE_0_Lay0_G_Ch0_Duet_Char_1024_X1024_d74b11db1a.png",
    name: "Pixel Pets: Retro Music Game",
    productCode: "DORY",
  },
  {
    icon: "https://storage.googleapis.com/product-management-portal/production/iconic_main_242ccb0e6c/iconic_main_242ccb0e6c.png",
    name: "Music Night Battle",
    productCode: "ANNA",
  },
  {
    icon: "https://storage.googleapis.com/product-management-portal/production/com_amanotes_beathopper_ba4330a60d/com_amanotes_beathopper_ba4330a60d.png",
    name: "Tiles Hop",
    productCode: "BH",
  },
  {
    icon: "https://storage.googleapis.com/product-management-portal/production/small_Screenshot_2024_04_26_at_09_39_28_f21ddaf083/small_Screenshot_2024_04_26_at_09_39_28_f21ddaf083.png",
    name: "Super Duet Dogs",
    productCode: "ELLA",
  },
  {
    icon: "https://storage.googleapis.com/product-management-portal/production/small_Screen_Shot_2024_06_10_at_20_10_1_c93a32c31b/small_Screen_Shot_2024_06_10_at_20_10_1_c93a32c31b.png",
    name: "Cat Way Home",
    productCode: "IDA",
  },

];
