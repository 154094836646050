// Syntax highligh
import React from "react";
import { CopyOutlined } from "@ant-design/icons";
import { Button, message } from "antd";
import { CubeContext } from "@cubejs-client/react";
import { format } from "sql-formatter";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";

type IProps = {
  query: any;
};
export default function QueryViewer({ query }: IProps) {
  const [messageApi, contextHolder] = message.useMessage();
  const [formattedSql, setFormattedSql] = React.useState("");
  const { cubeApi } = React.useContext(CubeContext);

  // initialize the formatted sql
  React.useEffect(() => {
    cubeApi
      .sql(query)
      .then((resultSet) => {
        const sql = format(resultSet.rawQuery().sql[0], {
          language: "bigquery",
          tabWidth: 2,
          keywordCase: "upper",
          linesBetweenQueries: 2,
          params: resultSet.rawQuery().sql[1].map((p) => `'${p}'`),
        });
        return sql;
      })
      .then((sql) => {
        setFormattedSql(sql);
      })
      .catch((reason) => {
        console.log(reason);
        setFormattedSql("");
      });
  }, [cubeApi, query]);

  function copySql() {
    navigator.clipboard.writeText(formattedSql);
    messageApi.open({
      type: "success",
      content: "Copy success!",
    });
  }

  return (
    <>
      {contextHolder}
      <div className="relative">
        <Button
          size="small"
          type="text"
          icon={<CopyOutlined />}
          className="absolute right-0 top-0"
          disabled={formattedSql === ""}
          style={{ color: formattedSql === "" ? "rgba(0, 0, 0, 0.25)" : "#096DD9" }}
          onClick={copySql}
        >
          Copy
        </Button>
        <SyntaxHighlighter language="sql" style={docco}>
          {formattedSql}
        </SyntaxHighlighter>
      </div>
    </>
  );
}
