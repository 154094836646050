import React from "react";
import { useGetIdentity, useLogout } from "@refinedev/core";
import clsx from "clsx";

// antd core
import { Button, Divider, Popover, Switch } from "antd";

// ant icons
import { DownOutlined, LogoutOutlined } from "@ant-design/icons";

// components
import { ProductAutoComplete } from "@src/components/auto-complete/product-auto-complete";

// types
import { IIdentity } from "@src/types/user";

// hooks
import { useClickOutside } from "@src/hooks/use-click-outside";

// context
import { useAppContext } from "@src/contexts/app-context";
import { useColorContext } from "@src/contexts/color-context";

function HeaderBar() {
  const { mutate: logout } = useLogout();
  const { data: identity } = useGetIdentity<IIdentity>();
  const { mode, setMode } = useColorContext();
  // state
  const [showProductAutoComplete, setShowProductAutoComplete] = React.useState<boolean>(false);
  // refs
  const productRef = useClickOutside(() => {
    setShowProductAutoComplete(false);
  });
  const { product } = useAppContext();

  function handleShowProduct() {
    setShowProductAutoComplete(true);
  }

  return (
    <div className="relative flex items-center h-full w-full justify-between">
      <div className="flex flex-grow items-center">
        {/* <Button
          type="text"
          icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={() => setCollapsed(!collapsed)}
          className="text-[16px] w-[64px] h-full text-[#fff]"
        /> */}

        {product && (
          <div
            className={clsx(
              "flex items-center relative z-10 cursor-pointer",
              !showProductAutoComplete ? "block" : "hidden",
            )}
            onClick={handleShowProduct}
          >
            <div className="w-[44px] h-[44px] relative mr-4">
              <img
                className="rounded-lg absolute h-full w-full object-cover"
                src={product.icon}
                alt="Product application"
              />
            </div>
            <div className="text-white mr-2">
              {product.name} - {product.productCode}
            </div>
            <DownOutlined style={{ color: "#fff" }} />
          </div>
        )}

        <div className={`${showProductAutoComplete ? "block" : "hidden"}`} ref={productRef}>
          <ProductAutoComplete
            setShowProductAutoComplete={setShowProductAutoComplete}
            showProductAutoComplete={showProductAutoComplete}
          />
        </div>
      </div>

      <div className="flex items-center">
        <Switch
          checkedChildren="🌛"
          unCheckedChildren="🔆"
          onChange={() => setMode(mode === "light" ? "dark" : "light")}
          defaultChecked={mode === "dark"}
          className="mr-4"
        />
        <Popover
          destroyTooltipOnHide
          arrow={false}
          placement="bottomLeft"
          content={
            <div className="-mx-2">
              <div className="px-4">
                <b>{identity?.email || "N/A"}</b> <br />
              </div>
              <Divider className="p-0 m-0 my-2" />
              <div className="flex items-center">
                <Button
                  type="text"
                  className="w-full text-left"
                  icon={<LogoutOutlined size={20} />}
                  onClick={() => logout()}
                >
                  Logout
                </Button>
              </div>
            </div>
          }
        >
          <div className="flex px-0 py-2 text-sm text-white transition-all ease-nav-brand flex-row items-center cursor-pointer  ml-auto">
            <div className="relative flex items-center mr-[10px]">
              <img className="w-[30px] h-[30px] rounded-full" src={identity?.avatar} alt="User" />
            </div>
            <span className="sm:inline text-ellipsis">{identity?.name || "N/A"}</span>
          </div>
        </Popover>
      </div>
    </div>
  );
}

export default HeaderBar;
