import React from "react";
import { createContext, useContext } from "react";
import { QueryBuilder } from "@cubejs-client/react";
import { useAppContext } from "./app-context";

export const ExploreContext = createContext({} as any);

type IProps = {
  children: React.ReactNode;
};

export const ExploreContextProvider: React.FC<IProps> = ({ children }) => {
  const { chartDefault, forceUpdate } = useAppContext();
  return (
    <div key={forceUpdate}>
      <QueryBuilder
        wrapWithQueryRenderer={false}
        disableHeuristics
        defaultQuery={chartDefault?.vizState?.query || {}}
        render={({ ...restProps }) => {
          return (
            <>
              <ExploreContext.Provider value={{ ...restProps }}>{children}</ExploreContext.Provider>
            </>
          );
        }}
      />
    </div>
  );
};

export const useExploreContext = () => useContext(ExploreContext);
